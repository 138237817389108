import { useTranslation } from "react-i18next";

/* Maintenance Mode */
export const MaintenanceMode = ({ children }) => {
  const { t } = useTranslation();
  const MAINTENANCE_MODE = parseInt(process.env.REACT_APP_MAINTENANCE_MODE, 10);

  if (MAINTENANCE_MODE) {
    return (
      <section className="login">
        <div className="bg-login" />
        <h1>{t("USER LOGIN")}</h1>
        <h2 className="warning vertical-spacing">
          {t("The ordering platform is currently in maintenance mode.")}
        </h2>
        <span className="vertical-spacing">
          {t("We are back in a few minutes. Thank you for your patience.")}
        </span>
      </section>
    );
  } else {
    return children;
  }
};
