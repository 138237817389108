import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { useForm } from "react-final-form";
import { format, parse, isValid, toDate } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import enUS from "date-fns/locale/en-US";

registerLocale("en", enUS);
registerLocale("en-US", enUS);
registerLocale("fr", fr);


// provide a spacer index 0
const Spacer = ({ index }) => {
  return index === 0 ? (
    <div style={{ marginBottom: "2rem" }}>
      <hr />
    </div>
  ) : null;
};

export const GenericFieldComponent = (props) => {
  const {
    field,
    input,
    id,
    meta: { error, warning, valid },
    disabled,
  } = props;

  return (
    <div className="indexedFormField">
      <div style={{ position: "relative" }}>
        <input
          {...input}
          min={field.min}
          max={field.max}
          placeholder={field.label}
          type={field.type}
          id={id}
          disabled={disabled}
        />
        {(error && <span className="validation-states error">{error}</span>) ||
          (warning && (
            <span className="validation-states warning">{warning}</span>
          )) ||
          (valid && <span className={`validation-states valid`} />)}
        <Spacer index={field.index} />
      </div>
    </div>
  );
};

export const CheckboxComponent = (props) => {
  const { input, field, id, disabled } = props;
  return (
    <div className="indexedFormField">
      <div
        className="formFieldWrapper"
        style={
          disabled
            ? { backgroundColor: "lightgrey", pointerEvents: "none" }
            : {}
        }
      >
        <label>
          <input
            {...input}
            placeholder={field.label}
            type={field.type}
            id={id}
          />
          {field.label}
        </label>
        <Spacer index={field.index} />
      </div>
    </div>
  );
};

export const SelectComponent = (props) => {
  const {
    field,
    input,
    disabled,
    meta: { error, warning, valid },
  } = props;
  const { value } = input;

  return (
    <div className="indexedFormField">
      <div style={{ position: "relative" }}>
        <select
          name={field.name}
          id={field.name}
          {...input}
          // for an empty value display the selection in gray
          style={{ color: value ? "black" : "#757575" }}
          disabled={disabled}
        >
          {field.children}
        </select>
        {(error && <span className="validation-states error">{error}</span>) ||
          (warning && (
            <span className="validation-states warning">{warning}</span>
          )) ||
          (valid && <span className={`validation-states valid`} />)}
        <Spacer index={field.index} />
      </div>
    </div>
  );
};

export const DateFieldComponent = (props) => {
  const {
    field,
    input,
    disabled,
    meta: { error, warning, valid },
  } = props;

  const { i18n } = useTranslation();

  const DATE_FORMAT = "yyyy/MM/dd"
  const dateValue = parse(input.value, DATE_FORMAT, new Date());

  return (
    <div className="indexedFormField">
      <div style={{ position: "relative" }}>
        <DatePicker
          locale={i18n.language}
          placeholderText={field.label}
          selected={dateValue && isValid(dateValue) ? toDate(dateValue) : null}
          disabledKeyboardNavigation
          disabled={disabled}
          onChange={(date) => {
            if (isValid(date)) {
              input.onChange(format(new Date(date), DATE_FORMAT));
            } else {
              input.onChange(null);
            }
          }}
          dateFormat={DATE_FORMAT}
          showYearDropdown
          dropdownMode="select"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
        {(error && <span className="validation-states error">{error}</span>) ||
          (warning && (
            <span className="validation-states warning">{warning}</span>
          )) ||
          (valid && <span className={`validation-states valid`} />)}
        <Spacer index={field.index} />
      </div>
    </div>
  );
};

export const CheckboxField = ({ field, disabled }) => {
  const isDisabled = disabled || field.read_only;
  const initialValue = field.default_value;
  return (
    <div>
      <Field {...field} initialValue={initialValue}>
        {(props) => (
          <CheckboxComponent
            field={field}
            {...props}
            disabled={isDisabled}
          />
        )}
      </Field>
    </div>
  );
};

export const SelectField = ({ field, disabled }) => {
  const { pk, ...rest } = field;
  const isDisabled = disabled || field.read_only;
  const initialValue = field.default_value;
  return (
    <div>
      <Field {...rest} name={field.name} pk={pk} initialValue={initialValue}>
        {(props) => (
          <SelectComponent
            field={field}
            {...props}
            disabled={isDisabled}
          />
        )}
      </Field>
    </div>
  );
};

export const DateField = ({ field, disabled }) => {
  const isDisabled = disabled || field.read_only;
  const initialValue = field.default_value;
  return (
    <div>
      <Field name={field.name} validate={field.validate} initialValue={initialValue}>
        {(props) => (
          <DateFieldComponent
            label={field.label}
            field={field}
            disabled={isDisabled}
            {...props}
          />
        )}
      </Field>
    </div>
  );
};

export const GenericField = ({ field, disabled }) => {
  const isDisabled = disabled || field.read_only;
  const initialValue = field.default_value;
  return (
    <div>
      <Field name={field.name} validate={field.validate} initialValue={initialValue}>
        {(props) => (
          <GenericFieldComponent
            label={field.label}
            field={field}
            disabled={isDisabled}
            {...props}
          />
        )}
      </Field>
    </div>
  );
};

const createKey = (field, form) => {
  const dependency = Object.keys(field.dependencies)[0];
  const dependentValue = form.getState().values[dependency];
  return [field.name, dependentValue].join("_");
};
export const DependentField = ({ field, disabled }) => {
  const form = useForm();
  const [key, setkey] = useState(createKey(field, form));
  const dependency = Object.keys(field.dependencies)[0];

  useEffect(() => {
    setkey(createKey(field, form));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getState().values[dependency]]);

  return (
    <div>
      <Field name={field.name} validate={field.validate} key={key}>
        {(props) => (
          <GenericFieldComponent
            key={key}
            label={field.label}
            field={field}
            disabled={disabled}
            {...props}
          />
        )}
      </Field>
    </div>
  );
};

export const FieldRenderer = ({ field, disabled }) => {
  // Special handling for the product-code-fields
  if (field.dependencies !== undefined)
    return <DependentField field={field} disabled={disabled} />;

  switch (field.type) {
    case "checkbox":
      return <CheckboxField field={field} disabled={disabled} />;
    case "select":
      return <SelectField field={field} disabled={disabled} />;
    case "date":
      return <DateField field={field} disabled={disabled} />;
    default:
      return <GenericField field={field} disabled={disabled} />;
  }
};
