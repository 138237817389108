import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useRegistration,
  checkIsRegistrationOfAdditionalUser,
  checkIsKnownCompany,
} from "./useRegistration";

export const RenderCheckboxGroup = ({
  initialValues,
  onChange,
  name,
  options,
  disabled,
}) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState(initialValues);

  useEffect(() => setSelectedValues(initialValues), [initialValues]);

  return (
    <div className="grid__item xs-12 md-12">
      {options.map((option, index) => (
        <div key={index} className={"checkbox grid__item xs-4 md-4"}>
          <input
            type="checkbox"
            className="vertical-spacing-half"
            name={`${name}[${index}]`}
            value={option.name}
            checked={selectedValues.indexOf(option.name) !== -1}
            disabled={disabled}
            onChange={(event) => {
              let newValue = [...selectedValues];
              if (event.target.checked) {
                newValue.push(option.name);
              } else {
                newValue.splice(newValue.indexOf(option.name), 1);
              }
              onChange(newValue);
              setSelectedValues(newValue);
            }}
          />
          <label
            htmlFor={option.name}
            style={{
              display: "inline-block",
              marginLeft: "0.5rem",
              marginTop: "-2.5rem",
              lineHeight: "2rem",
              verticalAlign: "middle",
            }}
          >
            {option.label}
          </label>
        </div>
      ))}
      {!disabled && !selectedValues.length > 0 && (
        <div className={"error-checkbox grid__item xs-12 md-12"}>
          {t("Choose at least one product group")}
        </div>
      )}
    </div>
  );
};

export const Quotas = () => {
  const { t } = useTranslation();
  const {
    selectedCompany,
    isRegistrationOfAdditionalUser,
    isKnownCompany,
    setQuotas,
  } = useRegistration((state) => ({
    selectedCompany: state.selectedCompany,
    coforMode: state.coforMode,
    isRegistrationOfAdditionalUser: checkIsRegistrationOfAdditionalUser(state),
    isKnownCompany: checkIsKnownCompany(state),
    setQuotas: state.setQuotas,
  }));
  const initialQuotas = selectedCompany?.quotas || [];

  // Do not render the quotas, when all we do is registering a new user
  if (isRegistrationOfAdditionalUser) return null;

  // use mapping here to get values into the translation process
  const quotaMapping = {
    MOPAR: t("MOPAR"),
    SUSTAINERA: t("SUSTAINera"),
    EUR: t("Eurorepar"),
    BPROAUTO: t("bproauto"),
  };

  const options = Object.keys(quotaMapping).map((name) => ({
    name: name,
    label: quotaMapping[name],
  }));

  return (
    <div>
      <div className="grid__item xs-12 vertical-spacing">
        <h3>{t("Product categories")}</h3>
        <h6>{t("You will be allowed to order labels for:")}</h6>
      </div>

      <div>
        <RenderCheckboxGroup
          initialValues={initialQuotas}
          options={options}
          name={"quotas"}
          disabled={isKnownCompany}
          error={false}
          onChange={(values) => setQuotas(values)}
        />
      </div>
      {isKnownCompany && (
        <div className="grid__item xs-12 vertical-spacing">
          <h6>
            {t(
              "If the authorization(s) do not match with your need, please contact"
            )}{" "}
            <a href="mailto:stellantis-secure-label@stellantis.com">
              stellantis-secure-label@stellantis.com
            </a>
          </h6>
        </div>
      )}
    </div>
  );
};
