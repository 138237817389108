import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";

import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { endpoint } from "../../../commonjs/endpoints";
import { NotificationTemplate } from "../../../layout/Layout";
import { useAuthentication } from "../../../commonjs/AuthenticationContext";

export const PasswordForm = ({ initialValues }) => {
  const { t } = useTranslation();

  const { account, companyProfile } = useAuthentication();
  const notify = (msg) => toast.success(msg, { duration: 5000 });

  const { mutate: resetPassword } = useMutation({
    mutationFn: () =>
      endpoint("accounts:api_reset_password", "POST", {
        body: { email: account.email, account_id: companyProfile.cofor10 },
      }),
    onSuccess: (data) => {
      notify(
        <NotificationTemplate
          title={t("Password reset")}
          message={t("A password reset link was sent to your email address.")}
        />
      );
    },
  });
  return (
    <Form
      initialValues={initialValues}
      onSubmit={resetPassword}
      validate={() => {}}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="grid__item xs-12">
              <button
                type="submit"
                disabled={submitting}
                className={`button button-primary ${
                  submitting ? "button-primary--disabled" : "clickable"
                }`}
              >
                {t("RESET")}
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
