import React, { Fragment } from "react";

import { CompanyInformation } from "./Profile/CompanyInformation";
import { ManageUsers } from "./Profile/ManageUsers";
import { AddressForm } from "./Profile/AddressForm";
import { ProfileForm } from "./Profile/ProfileForm";
import { PasswordForm } from "./Profile/PasswordForm";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "../../commonjs/AuthenticationContext";
import { Spinner } from "../../commonjs/components";

const Separator = () => {
  return (
    <div className="grid__item xs-12">
      <hr />
    </div>
  );
};

const FormLine = ({ children }) => {
  return (
    <Fragment>
      <div className="grid__item xs-12 sm-12 md-6">{children}</div>
      <Separator />
    </Fragment>
  );
};
export const Profile = () => {
  const { t } = useTranslation();
  const { account, companyProfile } = useAuthentication();

  if (!(account && companyProfile)) return <Spinner />;

  return (
    <section className="profile">
      <div className="grid__item xs-12">
        <h1>{t("UPDATE PROFILE")}</h1>
      </div>

      <CompanyInformation />
      <ManageUsers />

      <div className="grid__item xs-12">
        <hr />
      </div>

      {/* All companies can change their Billing-Address
       */}
      <FormLine>
        <h2>{t("Billing address")}</h2>
        <AddressForm initialValues={companyProfile.billing_address} />
      </FormLine>

      {/* only T-Accounts can change their delivery address */}
      {companyProfile.cofor10.startsWith("T") && (
        <FormLine>
          <h2>{t("Delivery address")}</h2>
          <AddressForm initialValues={companyProfile.delivery_address} />
        </FormLine>
      )}

      <div className="grid__item xs-12 sm-12 md-6 profile__address">
        <h2>{t("User profile")}</h2>
        <ProfileForm />
      </div>

      <div className="grid__item xs-12">
        <hr />
      </div>

      <div className="grid__item xs-12 sm-12 md-6 profile__password">
        <h2>{t("Reset password")}</h2>
        <span>
          {t(
            "Click the button below to obtain a password reset link per email."
          )}
        </span>
        <PasswordForm />
      </div>
    </section>
  );
};
