// import "./polyfills";

// app
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./i18next";
import { useLocation } from "react-router-dom";
import { useAuthentication } from "./commonjs/AuthenticationContext";
import { Navigate, BrowserRouter } from "react-router-dom";

import { Layout } from "./layout";

import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationContextProvider } from "./commonjs/NotificationContext";
import { AuthenticationContextProvider } from "./commonjs/AuthenticationContext";

import "./style/index.scss";

const queryClient = new QueryClient();

const RedirectToPrevUrl = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuthentication();

  if (isLoggedIn && location.state?.prevUrl) {
    return <Navigate to={location.state.prevUrl} />;
  } else return children;
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthenticationContextProvider>
          <NotificationContextProvider>
            <RedirectToPrevUrl>
              <Layout />
            </RedirectToPrevUrl>
          </NotificationContextProvider>
        </AuthenticationContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </I18nextProvider>,
  document.getElementById("react-root")
);

