import React from "react";
import { ModalDisableCompanyUsers } from "./ModalDisableCompanyUsers";
import { ModalEnableCompanyUsers } from "./ModalEnableCompanyUsers";

const MODAL_COMPONENTS = {
  DISABLE_COMPANY_USERS: ModalDisableCompanyUsers,
  ENABLE_COMPANY_USERS: ModalEnableCompanyUsers
};

export const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};
