import React from "react";
import { Field, Form } from "react-final-form";
import { useAuthentication } from "../../../commonjs/AuthenticationContext";

export const CompanyUsersForm = ({ initialValues, onSubmit, submitText }) => {
  const { account } = useAuthentication();

  const validate = (values) => {
    // return dummy error to block submit button
    return values.users.some((e) => e.value === true)
      ? {}
      : { no_company_user_selected: true };
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div className="company-users-container">
            {initialValues.users &&
              initialValues.users.map((user, id) => {
                return (
                  <div
                    className="grid__item xs-12 vertical-spacing-half"
                    key={id}
                  >
                    <label
                      className={`company-users-checkbox-label ${
                        user.pk === account.pk
                          ? "company-users-checkbox-label--disabled"
                          : ""
                      }`}
                    >
                      <Field
                        className="company-users-checkbox-input"
                        name={`users[${id}].value`}
                        component="input"
                        type="checkbox"
                        disabled={user.pk === account.pk ? true : null}
                      />
                      {user.first_name} {user.last_name} ({user.email})
                    </label>
                  </div>
                );
              })}
          </div>
          <button
            type="submit"
            disabled={pristine || submitting}
            className={`button button-primary ${
              pristine || submitting || invalid
                ? "button-primary--disabled"
                : "clickable"
            }`}
          >
            {submitText}
          </button>
        </form>
      )}
    </Form>
  );
};
