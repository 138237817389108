import React from "react";

import { Toaster } from "react-hot-toast";


import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { MainRoutes } from "../routing/routes";

import { NavigationMain } from "./NavigationMain";
import { NavigationSide } from "./NavigationSide";

import { AccountBox } from "../licensing/accounts";
import { useAuthentication } from "../commonjs/AuthenticationContext";

import { Footer } from "./footer";

export const NotificationTemplate = ({ title, message }) => {
  return (
    <div>
      <span>
        <b>{title}</b>
      </span>
      {message}
    </div>
  );
};
export const Layout = () => {
  const { isLoggedIn, hasRights } = useAuthentication();
  const { t } = useTranslation();

  return (
    <div className="container">
      <Toaster />
      <header className="header">
        <div className="grid vertical-spacing-half">
          <div className="grid__item xs-12">
            <Link className="logo left" to="/" />
            <div className="claim">
              <div className="claim-firstline">
                {t("Stellantis - Secure Labels")}
              </div>
              <div className="claim-secondline">{t("ordering platform")}</div>
            </div>
            <NavigationSide />
          </div>
        </div>

        <hr />

        {!isLoggedIn ? (
          ""
        ) : (
          <div>
            <div className="grid">
              <div className="grid__item left  xs-6 md-9 ">
                <NavigationMain hasRights={hasRights} />
              </div>

              <div className="grid__item right xs-6 md-3 vertical-spacing-half">
                <AccountBox />
              </div>
            </div>
            <hr className="nav-main-hr" />
          </div>
        )}
      </header>

      {/* ROUTING / MAIN CONTENT includes staging mode display */}
      <main className="grid">
        <div className="grid__item xs-12">
          <MainRoutes />
        </div>
      </main>

      <Footer />
    </div>
  );
};
