import React, { createContext, useContext, useState, useEffect } from "react";

import { useHeartBeat } from "./useHeartbeat";
import { endpoint } from "./endpoints";
import { Navigate } from "react-router-dom";
import { named_urls } from "../routing/known_urls";

export const AuthenticationContext = createContext();

const SessionVerification = ({ setPermissions, children }) => {
  const [sessionVerified, setSessionVerified] = useState(false);
  useEffect(() => {
    endpoint("accounts:api_login", "GET").then((result) => {
      if (!result.errors && result.permissions?.length > 0) {
        setPermissions(result.permissions);
      }
      setSessionVerified(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!sessionVerified) return <div></div>;
  else return children;
};

export const AuthenticationContextProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [user, setUser] = useState();
  const [passwordExpiredUrl, setPasswordExpiredUrl] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [timedOut, setTimedOut] = useState(false);

  const { startHeartbeat, stopHeartbeat } = useHeartBeat();

  // check if the session timed out
  useEffect(() => {
    const fetchSessionStatus = async () => {
      try {
        const sessionStatus = await endpoint("accounts:api_session_status", "GET");
        setTimedOut(Boolean(sessionStatus.timed_out));
      } catch (_) { }
    };
    fetchSessionStatus();
  }, [user]);

  useEffect(() => {
    const fetchUser = async () => {
      if (permissions && permissions.length > 0) {
        const users = await endpoint("accounts:api_user_list", "GET");
        setUser(users[0]);
        window.document.body.classList.remove("invert");
        startHeartbeat();
      } else {
        window.document.body.classList.add("invert");
        setUser(undefined);
        stopHeartbeat();
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const logout = () => {
    endpoint("accounts:api_logout", "POST", { body: {} }).then((result) => {
      setUser(undefined);
      setPermissions(undefined);
      document.cookie = "";
      // TODO that's a very weird way of making text readable...
      document.body.classList.add("invert");
      stopHeartbeat();
    });
  };

  const login = async ({ username, password, account_id, captchaResponse }) => {
    const result = await endpoint(
      "accounts:api_login",
      "POST",
      {
        body: {
          username,
          password,
          account_id,
          "g-recaptcha-response": captchaResponse,
        },
      },
      false
    );

    if (!result.errors && result?.permissions.length > 0) {
      setPermissions(result.permissions);
    } else {
      if (result.errors.type === "PASSWORD_EXPIRED") {
        let url = named_urls["accounts:reset_expired_password"];
        url = url.replace(":email", encodeURI(result.errors.email));
        url = url.replace(":account_id", encodeURI(result.errors.account_id));
        setPasswordExpiredUrl(url);
      } else {
        setError(result.errors.detail);
      }
    }
  };

  const companyProfile = user?.company_profile;

  return (
    <AuthenticationContext.Provider
      value={{
        login,
        logout,
        error,
        timedOut,
        isLoggedIn: user !== undefined,
        hasRights: permissions,
        setPermissions: setPermissions,
        companyProfile,
        blockedFromOrdering: companyProfile?.blocked_for_ordering,
        blockedCompletely: companyProfile?.blocked_completely,
        deliveryAddressApproved:
          companyProfile?.delivery_address.state === "APPROVED",
        deliveryAddressApprovalPending:
          companyProfile?.delivery_address.state ===
          "CLIENT_APPROVAL_REQUESTED",
        account: user,
      }}
    >
      <SessionVerification setPermissions={setPermissions}>
        {passwordExpiredUrl && <Navigate to={passwordExpiredUrl} />}
        {children}
      </SessionVerification>
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within AuthenticationContextProvider"
    );
  }

  return context;
};
