import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { endpoint } from "../../commonjs/endpoints";

export const PasswordExpiredRequest = () => {
  const { t } = useTranslation();
  const { email, account_id } = useParams();

  useEffect(
    () => {
      endpoint("accounts:api_reset_password", "POST", {
        body: { email, account_id, suppress_notification: true },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <section className="password-expired-request">
      <div className="bg-login" />
      <h1>{t("YOUR PASSWORD HAS EXPIRED")}</h1>
      <section>
        <span>
          {t(
            "An email with a password reset link has been sent to your email address: "
          )}
          {email}.
        </span>
        <br />
        <span>
          {t(
            "Please reset your password by clicking on the reset link and enter a new password."
          )}
        </span>
      </section>
    </section>
  );
};
