import React, { useRef } from "react";

import { NavLink, Navigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import ReCAPTCHA from "react-google-recaptcha";

import { i18n } from "../../i18next";
import { named_urls } from "../../routing/known_urls";
import { RenderField, RenderPasswordField } from "../../commonjs/components";
import { localSettings } from "../../settings";

import { useAuthentication } from "../../commonjs/AuthenticationContext";
import { MaintenanceMode } from "../../commonjs/components/MaintenanceMode";

/* Login Form */
const validate_login = (values) => {
  const errors = {};
  if (!values.username) errors.username = i18n.t("Required");
  if (!values.account_id) errors.account_id = i18n.t("Required");
  if (!values.password) errors.password = i18n.t("Required");
  return errors;
};

const LoginForm = () => {
  const { t, language } = useTranslation();
  const captchaRef = useRef();

  const { login, error } = useAuthentication();
  const loginWithCaptcha = async ({ username, password, account_id }) => {
    const captchaResponse = await captchaRef.current.executeAsync();
    login({ username, password, account_id, captchaResponse });
  };

  return (
    <Form
      onSubmit={loginWithCaptcha}
      validate={validate_login}
    >
      {({ handleSubmit, pristine, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <ReCAPTCHA
            locale={language}
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            size={"invisible"}
            badge="bottomright"
          />
          <div className="grid__item xs-12 md-4 vertical-spacing">
            <Field
              name="username"
              component={RenderField}
              type="text"
              placeholder={t("Email")}
            />
          </div>
          <div className="grid__item xs-12 md-4 vertical-spacing">
            <Field
              name="account_id"
              component={RenderField}
              type="text"
              placeholder={t("Account Id")}
            />
          </div>
          <div className="grid__item xs-12 md-4 vertical-spacing">
            <Field
              name="password"
              component={RenderPasswordField}
              type="password"
              placeholder={t("Password")}
            />
          </div>
          {error && (
            <span className="grid__item xs-12 vertical-spacing form-error">
              {error}
            </span>
          )}
          <span className="grid__item xs-12 vertical-spacing">
            <NavLink to={named_urls["accounts:reset_password"]}>
              <span>{t("Forgot your password?")}</span>
            </NavLink>
          </span>
          {!localSettings.accountsHideRegisterLink && (
            <div className="grid__item xs-12  vertical-spacing">
              <NavLink to={named_urls["accounts:register"]}>
                <span>{t("Register account or a new user")}</span>
              </NavLink>
            </div>
          )}

          <button
            type="submit"
            className={`grid__item xs-3 button vertical-spacing ${pristine || invalid || submitting
              ? "button-primary--disabled"
              : "clickable"
              }`}
          >
            {t("LOGIN")}
          </button>
        </form>
      )}
    </Form>
  );
};

export const Login = () => {
  const { t } = useTranslation();
  const { isLoggedIn, timedOut } = useAuthentication();

  if (isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: named_urls["Welcome"],
          state: { from: window.location },
        }}
      />
    );
  }

  return (
    <MaintenanceMode>
      <section className="login">
        <div className="bg-login" />
        <h1>{t("USER LOGIN")}</h1>
        {timedOut && (
          <h2 className="warning vertical-spacing">
            {t("Your session has expired.")}
          </h2>
        )}
        <span className="vertical-spacing">
          {t("Please login for ordering your security labels.")}
        </span>
        <div className="grid vertical-spacing">
          <LoginForm />
        </div>
      </section>
    </MaintenanceMode>
  );
};
