import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { CompanyUsersForm } from "./CompanyUsersForm";
import { endpoint } from "../../../commonjs/endpoints";
import { Spinner } from "../../../commonjs/components";
import { NotificationTemplate } from "../../../layout/Layout";

import { ModalRoot } from "../modals/ModalRoot";

export const ALL_USERS_LIST = "all_users_list";

export const ManageUsers = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [modalState, setModalState] = useState({
    modalType: null,
    modalProps: {},
  });

  const showModal = ({ modalType, modalProps }) => {
    setModalState({ modalType, modalProps });
  };
  const hideModal = () => setModalState({ modalType: null, modalProps: {} });

  const notify = (msg) => toast.success(msg, { duration: 5000 });

  const { data: users, isLoading } = useQuery({
    queryKey: ALL_USERS_LIST,
    queryFn: () =>
      endpoint("accounts:api_user_list", "GET", { query: { q_showall: true } }),
  });

  const { mutate: enableUsers } = useMutation({
    mutationFn: (users) =>
      endpoint("accounts:api_users_enable", "POST", {
        body: users,
      }),
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: [ALL_USERS_LIST],
      });
      // TODO header for notifications!
      // this message as headline "Active company users"
      notify(
        <NotificationTemplate
          title={t("Inactive company users")}
          message={t(
            "User(s) successfully enabled. An email was sent to the provisioning manager of your company."
          )}
        />
      );
      hideModal();
    },
  });
  const { mutate: disableUsers } = useMutation({
    mutationFn: (users) =>
      endpoint("accounts:api_users_disable", "POST", {
        body: users,
      }),
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({
        queryKey: [ALL_USERS_LIST],
      });
      // TODO header for notifications!
      notify(
        <NotificationTemplate
          title={t("Active company users")}
          message={t(
            "User(s) successfully disabled. An email was sent to the provisioning manager of your company."
          )}
        />
      );
      hideModal();
    },
  });

  if (isLoading) return <Spinner />;

  const activeUsers = users.filter((u) => u.is_active);
  const inactiveUsers = users.filter((u) => !u.is_active);

  return (
    <Fragment>
      <ModalRoot
        modalType={modalState.modalType}
        modalProps={modalState.modalProps}
      />
      <div className="grid__item xs-12 sm-12 md-6">
        <h2>{t("Active company users")}</h2>
        <CompanyUsersForm
          initialValues={{ users: activeUsers }}
          onSubmit={(values) => {
            showModal({
              modalType: "DISABLE_COMPANY_USERS",
              modalProps: {
                users: values.users,
                handleOk: () =>
                  disableUsers(
                    values.users.filter((user) => user.value === true)
                  ),
                handleCancel: () => hideModal(),
              },
            });
          }}
          submitText={t("DISABLE SELECTED COMPANY USERS")}
        />
      </div>

      <div className="grid__item xs-12 sm-12 md-6">
        <h2>{t("Inactive company users")}</h2>
        <CompanyUsersForm
          initialValues={{ users: inactiveUsers }}
          onSubmit={(values) => {
            showModal({
              modalType: "ENABLE_COMPANY_USERS",
              modalProps: {
                users: values.users,
                handleOk: () =>
                  enableUsers(
                    values.users.filter((user) => user.value === true)
                  ),
                handleCancel: () => hideModal(),
              },
            });
          }}
          submitText={t("ENABLE SELECTED COMPANY USERS")}
        />
      </div>
    </Fragment>
  );
};
