import React, { useState } from "react";

import Select from "react-select";
import { useCountryOptions } from "../../../../commonjs/components/useCountryOptions";
import { useTranslation } from "react-i18next";

export const CountryFilter = ({ updateFilter }) => {
  const { t } = useTranslation();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const { countryOptions } = useCountryOptions();

  const availableCountries = countryOptions
    .map((entry) => ({
      label: entry.name + " (" + entry.code + ")",
      value: entry.code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const filterCountries = (selectedOptions) => {
    updateFilter((selectedOptions || []).map((entry) => entry.value));
    setSelectedCountries(selectedOptions || []);
  };

  return (
    <div className={"select-filter"}>
      <label>{t("Country")}</label>
      <Select
        isMulti
        simpleValue
        openOnFocus={true}
        options={availableCountries}
        onChange={filterCountries}
        value={selectedCountries}
        placeholder={t("Select one or more countries")}
      />
    </div>
  );
};
