import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DateFilterForQueries } from "../filters/DateFilterForQueries";

import { VerificationsMap } from "../authentications/Map/VerificationsMap";
import { HitCount } from "../authentications/HitCount";
import { SupplierTable } from "./SupplierTable";
import { SupplierFilter } from "./SupplierFilter";
import { ProductFilter } from "./ProductFilter";
import { CountryFilter } from "./CountryFilter";

import { VerificationStatusFilter } from "../authentications/VerificationStatusFilter";
import { DomainFilter } from "../authentications/DomainFilter";
import { Cofor10Filter } from "./Cofor10Filter";

export const SupplierAnalysis = () => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState({ dateStart: new Date("2018-01-01"), dateEnd: new Date() });
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);

  const updateDateRange = (dateStart, dateEnd) => setDateRange({ dateStart, dateEnd });
  const onFilterSuppliers = (suppliers) => setSelectedSuppliers(suppliers.map((entry) => entry.value));
  const onFilterProducts = (products) => setSelectedProducts(products.map((entry) => entry.value));
  const onSelectVerificationStatus = (states) => setSelectedStates(states);
  const onSelectCountries = (countries) => setSelectedCountries(countries);
  const onSelectDomain = (domains) => {
    // react-select returns null if no option is selected
    domains = domains || [];
    setSelectedDomains(domains.map((domain) => domain.value));
  };

  const params = {
    date_start: dateRange.dateStart,
    date_end: dateRange.dateEnd,
    company_ids: selectedSuppliers,
    customer_references: selectedProducts,
    verification_status: selectedStates,
    selected_countries: selectedCountries,
    domains: selectedDomains,
  };

  return (
    <section className={"statistics"}>
      <h1>{t("SUPPLIERS SCAN ANALYSIS")}</h1>
      <div className={"grid query-params filter-panel"}>
        <div className={"grid__item xs-12"}>
          <div className={"grid__item xs-5"}>
            <DateFilterForQueries
              onChange={updateDateRange}
              date_start={dateRange.dateStart}
              date_end={dateRange.dateEnd}
            />
          </div>
          <div className={"grid__item xs-7"}>
            <VerificationStatusFilter onChange={onSelectVerificationStatus} />
          </div>
        </div>
        <div className={"grid__item xs-12"}>
          <div className={"grid__item xs-5"}>
            <Cofor10Filter filter={onFilterSuppliers} />
          </div>
          <div className={"grid__item xs-7"}>
            <SupplierFilter filter={onFilterSuppliers} />
          </div>
        </div>
        <div className={"grid__item xs-12"}>
          <div className={"grid__item xs-5"}>
            <ProductFilter filter={onFilterProducts} />
          </div>
          <div className={"grid__item xs-7"}>
            <CountryFilter updateFilter={onSelectCountries} />
          </div>
        </div>
        <div className={"grid__item xs-12"}>
          <div className={"grid__item xs-5"}>
            <DomainFilter onChange={onSelectDomain} />
          </div>
          <div className={"grid__item xs-7"}>&nbsp;</div>
        </div>
      </div>
      <h3>
        <HitCount queryParams={params} />{" "}
        {t("Verification(s) match(es) your criteria")}
      </h3>
      <SupplierTable params={params} />
      <div className="map" style={{ width: "100%", height: "600px" }}>
        <VerificationsMap params={params} options={{ hideLegend: false }} />
      </div>
    </section>
  );
}
