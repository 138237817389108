import React from "react";
import { NavLink } from "react-router-dom";

// use local patched version instead of npm package
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from "./react-simple-dropdown/dropdown";

import { i18n } from "../i18next";

import { useTranslation } from "react-i18next";

import { ModalMobileNavSide } from "./ModalMobileNavSide";
import { named_urls } from "../routing/known_urls";
import { Icon } from "../commonjs/icons";
import { useAuthentication } from "../commonjs/AuthenticationContext";

// navlinks have an active style
const Navigate = ({ onClick, children, ...rest }) => (
  <NavLink
    onClick={onClick}
    {...rest}
    className={({ isActive }) => [isActive ? "nav__item--active" : ""]}
  >
    {children}
  </NavLink>
);

export const LanguageDropdown = ({ inverted }) => {
  const { t } = useTranslation();

  return (
    <Dropdown>
      <DropdownTrigger className="nav-language__trigger">
        <Icon icon="globe" size="lg" />
        <span className="nav-label">{t("LANGUAGE")}</span>
        <DropdownContent>
          <ul className={`nav language-switch ${inverted ? "invert" : null}`}>
            {/*translation={t('English')}*/}
            {/*translation={t('French')}*/}
            <li
              onClick={(e) => i18n.changeLanguage("en")}
              className="icon icon-eng"
            >
              English
            </li>
            <li
              onClick={(e) => i18n.changeLanguage("fr")}
              className="icon icon-fra"
            >
              Français
            </li>
          </ul>
        </DropdownContent>
      </DropdownTrigger>
    </Dropdown>
  );
};

export const NavSideLinkList = () => {
  const { t } = useTranslation();
  const { isLoggedIn, logout } = useAuthentication();

  return (
    <nav className="nav-side">
      {isLoggedIn ? (
        // logged in
        <ul className="nav">
          <li className="nav__item">
            <a
              href="https://scribos360.io/ST3S/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon icon="shield-check" size="lg" />
              <span className="nav-label"> {t("SCRIBOS360")}</span>
            </a>
          </li>
          <li className="nav__item">
            <Navigate to={named_urls["contact:contact"]}>
              <Icon icon="envelope" size="lg" />
              <span className="nav-label"> {t("contact")}</span>
            </Navigate>
          </li>
          <li className="nav__item">
            <LanguageDropdown />
          </li>
          <li onClick={logout} className="nav__item">
            <span>
              <i className="fa fa-lg fa-sign-out" />
              <Icon icon="sign-out" size="lg" />
              <span className="nav-label"> {t("LOGOUT")}</span>
            </span>
          </li>
        </ul>
      ) : (
        //not logged in
        <ul className="nav">
          <li className="nav__item">
            <LanguageDropdown inverted={true} />
          </li>
        </ul>
      )}
    </nav>
  );
};

export class NavigationSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNavigationModal: false,
    };
    this.showNavigationModal = this.showNavigationModal.bind(this);
    this.hideNavigationModal = this.hideNavigationModal.bind(this);
  }

  showNavigationModal() {
    this.setState({
      showNavigationModal: true,
    });
  }

  hideNavigationModal() {
    this.setState({
      showNavigationModal: false,
    });
  }

  render() {
    return (
      <div className="right nav-side-container">
        <ModalMobileNavSide
          isOpen={this.state.showNavigationModal}
          close={this.hideNavigationModal}
        />
        <div
          className="hamburger mobile-only"
          onClick={this.showNavigationModal}
        />
        <div className="desktop-only">
          <NavSideLinkList onClick={null} />
        </div>
      </div>
    );
  }
}
