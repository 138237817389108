import React from "react";
import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";

import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { endpoint } from "../../../commonjs/endpoints";
import { NotificationTemplate } from "../../../layout/Layout";

import { RenderField } from "../../../commonjs/components";
import { normalizePhone } from "../utils";
import { useAuthentication } from "../../../commonjs/AuthenticationContext";

export const ProfileForm = () => {
  const { t } = useTranslation();

  const { account } = useAuthentication();

  const notify = (msg) => toast.success(msg, { duration: 5000 });

  const { mutate: updateUserprofile } = useMutation({
    mutationFn: (userData) =>
      endpoint("accounts:api_user", "PATCH", {
        param: userData.pk,
        body: userData,
      }),
    onSuccess: (data) => {
      notify(
        <NotificationTemplate
          title={t("User profile")}
          message={t("User profile successfully changed.")}
        />
      );
    },
  });
  const validateProfile = (values) => {
    const errors = {};
    if (!values.first_name) errors.first_name = t("Required");
    if (!values.last_name) errors.last_name = t("Required");
    if (!values.phone) errors.phone = t("Required");
    if (values.phone && values.phone.length < 8)
      errors.phone = t("Please provide at least 8 digits");
    return errors;
  };

  return (
    <Form
      initialValues={account}
      onSubmit={updateUserprofile}
      validate={validateProfile}
    >
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="grid__item xs-12">
              <Field
                name="email"
                component={RenderField}
                type="text"
                placeholder={t("Email")}
                disabled={true}
              />
            </div>
            <div className="grid__item xs-12">
              <Field
                name="first_name"
                component={RenderField}
                type="text"
                placeholder={t("First name")}
                disabled={true}
              />
            </div>
            <div className="grid__item xs-12">
              <Field
                name="last_name"
                component={RenderField}
                type="text"
                placeholder={t("Last name")}
                disabled={true}
              />
            </div>
            <div className="grid__item xs-12">
              <Field
                name="phone"
                component={RenderField}
                type="text"
                placeholder={t("Phone")}
                normalize={normalizePhone}
              />
            </div>
            <div className="grid__item xs-12">
              <button
                type="submit"
                disabled={pristine || submitting}
                className={`button button-primary ${pristine || submitting || invalid
                    ? "button-primary--disabled"
                    : "clickable"
                  }`}
              >
                {t("UPDATE")}
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
