import React from "react";
import { useTranslation } from "react-i18next";

import { localSettings } from "../../../settings";
import { useAuthentication } from "../../../commonjs/AuthenticationContext";

export const CompanyInformation = () => {
  const { t } = useTranslation();
  const { companyProfile } = useAuthentication();

  return (
    <div className="grid__item xs-12 sm-12 md-12">
      <h2>{t("Company information")}</h2>
      <div className="company-profile-container">
        <div className="grid__item xs-12 vertical-spacing-half">
          {companyProfile.name}
        </div>
        {!localSettings.accountsHideAccountBoxLine && (
          <div className="grid__item xs-12 vertical-spacing-half">
            {t("COFOR6")}: {companyProfile.cofor6}
          </div>
        )}
        <div className="grid__item xs-12 vertical-spacing-half">
          {t("Account id")}: {companyProfile.cofor10}
        </div>
        <div className="grid__item xs-12 vertical-spacing-half">
          {t("VAT")}: {companyProfile.vat_identification_no}
        </div>
      </div>
    </div>
  );
};
