import React, { useState } from "react";
import ReactModal from "react-modal";

export const MESSAGE = 0;
export const INFO = 1;
export const WARN = 2;
export const CRITICAL = 3;

export const useModal = ({ level }) => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => setOpen(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const modalClassName =
    level >= 3 ? "modal__content--alert" : "modal__content";

  return [
    ({ children }) => (
      <ReactModal
        isOpen={open}
        onRequestClose={handleCancel}
        shouldCloseOnOverlayClick={false}
        className={modalClassName}
        overlayClassName="modal__overlay"
        bodyOpenClassName="modal__body--open"
        contentLabel="ModalDialog"
        ariaHideApp={false}
      >
        <div onClick={handleCancel} className="modal-close" />
        {level >= 3 ? (
          <div className="modal--alert">{children}</div>
        ) : (
          <div>{children}</div>
        )}
      </ReactModal>
    ),
    openModal,
    closeModal,
  ];
};
