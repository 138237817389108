import { useInterval } from "./useInterval";
import { endpoint } from "./endpoints";

export const SESSION_HEARTBEAT_INTERVAL_SECONDS_DEFAULT = 300;
export const useHeartBeat = (
  delay = SESSION_HEARTBEAT_INTERVAL_SECONDS_DEFAULT * 1000
) => {
  const { start, stop } = useInterval(
    () => endpoint("accounts:api_session_heartbeat", "GET"),
    delay
  );
  return { startHeartbeat: start, stopHeartbeat: stop };
};
